#esp-search {
  padding-right: 0.75em;
  width: 80vw;
  transition: margin 100ms ease-in-out, width 100ms ease-in-out;
  margin: 0;
  display: none;

  .focus-box {
    position: relative;
    //background: #fff;
    margin: -0.75rem;
    padding: 0.75rem;
    z-index: 5;

    .list-box {
      background: #fff;
      box-shadow: 0 1em 3.5em -1em rgba(#000, 0.75);
      position: absolute;
      width: 100%;
      height: 40vh;
      padding: calc(40px + 1.5rem) 0.75rem 0;
      top: 0;
      left: 0;
      right: 0;
      max-height: 0;
      opacity: 0;
      display: none;
      border-radius: 2rem;
      transition: opacity 300ms ease-in-out, max-height 200ms ease-in-out,
        border-radius 200ms ease, transform 200ms ease;
    }
    .use-get-help-reminder {
      display: none;
      text-align: center;
      position: absolute;
      margin-top: none;
      width: 100%;
      padding: 64px 72px 24px;

      top: -1em;
      left: 0;
      right: 0;
    }
  }

  .scrim {
    background: linear-gradient(rgba(#000, 0.1), rgba(#000, 0.2));
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 400ms ease, transform 100ms ease;
  }

  &.focused {
    z-index: 9999;
    width: 100%;
    margin-left: calc(0.75em - 20vw);
    //margin-right: -54px;

    .list-box {
      opacity: 1;
      display: block;
      max-height: calc(95vh);
      border-radius: 0;
      transition: max-height 400ms ease-in-out, border-radius 200ms ease;

      @include breakpoint(medium) {
        max-height: calc(95vh);
      }
    }

    .scrim {
      opacity: 1;
      transform: translateY(0);
    }

    @include breakpoint(medium) {
      width: 100%;
      margin: 0;
    }

    input {
      padding-right: 7em !important;
    }
  }
  &.focusHelpText {
    z-index: 9999;
    width: 100%;
    .use-get-help-reminder {
      display: block;
      border-radius: 0;
    }
  }
  .ui.input {
    position: relative;
    z-index: 10;
    position: relative;

    input {
      border-radius: 40px;
      color: #fff;
      //  background: rgba(#000,.2);
      border-radius: 3em !important;

      &::placeholder {
        color: rgba(#fff, 0.6);
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-color: #ccc;
        color: #fff;
      }
    }

    &.loading {
      .icon {
        opacity: 1 !important;

        &:after {
          border-color: #fff transparent transparent;
        }
      }
    }

    > .icon {
      color: #fff;
    }

    > .ui.button {
      height: 100%;
      color: #fff;
      position: absolute;
      background: transparent;
      z-index: 10;
      right: 0;
      line-height: 1em;

      i.icon {
        height: 1.25em;
      }
    }
  }

  @include breakpoint(medium) {
    display: block;
    width: calc(100% - #{map-get($esp-menu-width, small)});
  }

  @include breakpoint(large) {
    padding-right: 0;
    max-width: calc(100% - #{(2 * map-get($esp-menu-width, small))});
  }

  @include breakpoint(xlarge) {
    max-width: calc(100% - #{(2 * map-get($esp-menu-width, large))});
  }

  @include breakpoint(xxlarge) {
    max-width: calc(
      #{map-get($breakpoints, xxlarge)} - #{(
          2 * map-get($esp-menu-width, large)
        )}
    );
  }
}

body.esp-catalog {
  #esp-search.focused {
    width: calc(100% + 54px - 1.75em);
    margin-right: -54px;

    @include breakpoint(medium) {
      margin-right: 0;
    }
  }
}
