.esp-equipment-detail {
  padding: 1em;
  position: fixed;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;

  @include breakpoint(large) {
    width: $esp-main-width;
  }
}
