.esp-equipment-list {
  position: relative;
  width: 100%;
  height: 100%;

  > .scroll-area {
    .scroll-content {
      > .ui.button {
        margin: 1em;
        max-width: calc(100% - 2em);
      }

      .ui.accordion .title {
        .ui.header {
          padding-left: 0.25rem;
          padding-right: 0.5rem;
        }
      }

      .ui.list {
        .item {
          padding-left: 1rem;
          padding-right: 1rem;
        }
        .item.link:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
}
