div._UserSelectSearchModule {
  width: 100%;
  padding: 1em;
  .ui.search {
    .ui.icon.input {
      width: 100%;
    }
  }
  label {
    display: block;
    padding: 0 1em 1em 0;
  }
}
