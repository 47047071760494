$scroll-width: 0.8em;

.scroll-area {
  &.scrolling {
    &:after {
      content: ' ';
      display: block;
      opacity: 0.7;
      border-left: 0.375rem solid transparent;
      border-right: 0.375rem solid transparent;
      border-top: 0.5rem solid map-get($demo-colors, primary);
      position: absolute;
      bottom: 0.25rem;
      right: 0.25rem;
      width: 0;
      height: 0;
      animation: indicating 2s infinite;
    }
  }

  .scroll-track {
    padding: 2px;
    z-index: 1001;
    transition: all 200ms ease-in-out !important;

    &:hover,
    &:active {
      .scroll-thumb {
        background: rgba(#000, 0.5);
        border: 1px solid rgba(#fff, 0.5);
      }
    }

    &.vertical {
      height: 100%;
      right: 0;
      width: $scroll-width !important;

      &:hover,
      &:active {
        padding-left: 0;
      }
    }

    &.horizontal {
      width: 100%;
      bottom: 0;
      height: $scroll-width !important;

      &:hover,
      &:active {
        padding-top: 0;
      }
    }
  }

  .scroll-thumb {
    background: rgba(#000, 0.375);
    border: 1px solid rgba(#fff, 0.375);
    border-radius: 1em;
    cursor: pointer;
    transition: background 200ms ease-in-out, border 200ms ease-in-out;
  }
}

@keyframes indicating {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-0.625rem);
  }

  60% {
    transform: translateY(-0.25rem);
  }
}
