.ui.list {
  &.cart-list {
    .esp-list-footer {
      .quantity {
        .ui.selection.dropdown {
          font-size: 0.875rem;
          margin-right: 0.5em;
          min-width: 4em;

          .dropdown.icon {
            font-size: 0.8rem;
          }

          .menu {
            .item {
              font-size: 0.875em;
            }
          }
        }
      }

      .price {
        order: 10;
      }
    }

    + .cart-footer {
      .item {
        padding: 0.75em;
      }
    }
  }
}
