//Chat PAGE
.chat-input-wrapper {
  textarea {
    resize: none;
    color: black;
    display: block;
    width: 80%;
    float: left;
    height: 3.05em;
    padding: 0.3em 1em;
    margin: 0.4rem 0.2rem;
  }
}

.chat-buttons {
  float: left;
  width: 15%;
  margin: 1%;

  button.chat-submit {
    background-color: grey;
    opacity: 1;
    margin-top: 0rem;
  }
}
