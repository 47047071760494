.block {
  &[data-blocktype='field'] {
    //    .field {
    //     margin: 0 3.5em;
    //    }
    //
    //    input {
    //      text-align: center;
    //
    //      &::placeholder {
    //        transition: all 200ms ease-in-out;
    //      }
    //
    //      &:focus {
    //        &::placeholder {
    //          opacity: 0 !important;
    //        }
    //      }
    //    }
  }

  &[data-template='field01'] {
  }

  &[data-template='copyField01'] {
    text-align: center;
    .field {
      margin: 0 auto;

      > label {
        display: none;
      }
    }
    input {
      text-align: center;
    }
  }
}
