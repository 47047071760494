.esp-tenant-preview {
  position: relative;
  height: 400px;
  background: #eaeaea;
  overflow: hidden;

  > .ui.image {
    position: absolute;
    max-width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.background {
      max-width: none;

      &.landscape {
        height: 100%;
        width: auto;
      }

      &.portrait {
        height: auto;
        width: 100%;
      }
    }

    &.meta {
      max-width: none;

      &.landscape {
        height: auto;
        width: 100%;
      }

      &.portrait {
        height: 100%;
        width: auto;
      }
    }
  }
}
