.block {
  &[data-template='hero01'] {
    text-align: center;

    * + * {
      margin-top: 1em;
    }

    .headline {
      margin: 0;
      font-size: 1.2rem;
    }

    .image {
      width: 80px;
      max-width: 50%;

      &.large {
        width: 150px;
      }

      &.avatar {
        border-radius: 50%;
      }
    }

    &.padding {
      padding-bottom: 0;
    }
  }

  &[data-template='hero02'] {
    display: flex;
    align-items: center;
    padding: 16px 32px;

    .headline {
      margin: 0;
      font-size: 1.2rem;
      order: 2;
      margin-left: 1em;
    }

    .image {
      width: 60px;
      max-width: 30%;

      &.large {
        width: 80px;
      }

      &.avatar {
        border-radius: 50%;
      }
    }

    &.padding {
      padding-bottom: 0;
    }
  }
}
