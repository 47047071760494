.block {
  &[data-blocktype='button'] {
    text-align: center;
  }

  &[data-template='button01'] {
  }

  &[data-template='submitcartbutton01'] {
  }
}
