.edit-profile {
  .fields {
    @include breakpoint(medium down) {
      .public-phone {
        margin: 0 0 1em !important;

        label.hidden {
          display: none !important;
        }
      }
    }
  }

  .image-select {
    .ui.image {
      width: 100%;
    }
  }
}
