.esp-section-title {
  .ui.menu {
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    max-width: calc(
      #{(map-get($esp-secondary-width, large) + $esp-main-width)} - 1em
    );
  }
}
