.esp-slider {
  overflow: hidden;

  .slides-container,
  .slides-nav {
  }

  .slides-container {
    height: calc(100% - 2.5em);
    transition: transform 200ms ease-in-out;

    &:after {
      content: ' ';
      display: table;
      clear: both;
    }
  }

  .slide {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    height: 100%;

    > img {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .slides-nav {
    text-align: center;
    margin: 0.5em 0;

    a {
      display: inline-block;
      width: 0.75em;
      height: 0.75em;
      border-radius: 50%;
      background: lighten(map-get($demo-colors, gray), 40%);
      border: 1px solid map-get($demo-colors, gray);
      margin: 0 0.25em;

      &.active {
        background: map-get($demo-colors, gray);
      }
    }
  }
}
