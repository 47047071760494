.esp-categories {
  .ui.breadcrumb {
    padding: 0.75em;
  }

  .ui.grid {
    border-top: 1px solid #f1f1f1;

    &[class*='three column'] {
      .column {
        &:nth-child(3n) {
          box-shadow: 0 1px 0 #f1f1f1;
        }
      }
    }

    &[class*='two column'] {
      .column {
        font-size: 1.125em;

        &:nth-child(2n) {
          box-shadow: 0 1px 0 #f1f1f1;
        }
      }
    }

    .column {
      position: relative;
      font-size: 0.875em;
      box-shadow: 1px 1px 0 #f1f1f1;

      &:after {
        content: ' ';
        display: block;
        padding-bottom: 100%;
      }

      .ui.button {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0.25em;
        border-radius: 0;
        text-transform: capitalize;
        font-size: 0.875em;
        background: rgba(#f1f1f1, 0);
        transition: all 200ms ease-in-out;

        img,
        svg,
        .icon {
          margin: 0 auto;
          display: block;
        }

        img,
        svg {
          width: 4.5rem;
        }

        .icon {
          font-size: 3em;
          line-height: 1em;
        }

        &:hover {
          background: rgba(#f1f1f1, 0.5);
        }

        &:active,
        &.active {
          background: rgba(#f1f1f1, 0.8);
        }
      }
    }
  }
}
