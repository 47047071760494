@import './variables.scss';

$caret-top: $react-dates-spacing-vertical-picker -
  $react-dates-width-tooltip-arrow / 2;

.DateInput {
  // font
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: $react-dates-color-placeholder-text;
  margin: 0;
  padding: 0;

  background: $react-dates-color-white;
  position: relative;
  display: inline-block;
  width: $react-dates-width-input;
  vertical-align: middle;
}

.DateInput--with-caret::before,
.DateInput--with-caret::after {
  content: '';
  display: inline-block;
  position: absolute;
  bottom: auto;
  border: $react-dates-width-tooltip-arrow / 2 solid transparent;
  border-top: 0;
  left: 22px;
  z-index: $react-dates-z-index + 2;
}

.DateInput--with-caret::before {
  top: $caret-top;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.DateInput--with-caret::after {
  top: $caret-top + 1;
  border-bottom-color: $react-dates-color-white;
}

.DateInput--disabled {
  background: $react-dates-color-gray-lighter;
}

.DateInput__input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  height: 100%;
  width: 100%;

  &[readonly] {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.DateInput__display-text {
  padding: 4px 8px;
  white-space: nowrap;
  overflow: hidden;
}

.DateInput__display-text--has-input {
  color: $react-dates-color-text;
}

.DateInput__display-text--focused {
  background: $react-dates-color-focus;
  border-color: $react-dates-color-focus;
  border-radius: 3px;
  color: $react-dates-color-text-focus;
}

.DateInput__display-text--disabled {
  font-style: italic;
}
