._ {
  align-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0.75rem;
  bottom: 0;
  cursor: zoom-out;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 9999;
  & > div {
    height: 100%;
    width: 100%;
    cursor: default;
  }
  @media (min-width: 768px) {
    padding: 1em;
  }
  @media (min-width: 1024px) {
    padding: 2em;
  }
}
