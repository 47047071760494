.esp-main-panel {
  height: 100%;
  background: #fff;
  position: relative;

  > .ui.menu {
    border-width: 0 0 1px 0;
    box-shadow: none;
    margin-bottom: 0;
    max-width: 100%;

    > .item {
      max-width: calc(100% - 100px);
      @include breakpoint(large) {
        max-width: 100%;
      }

      > span {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    > .header.item {
      -webkit-user-select: auto;
      -moz-user-select: auto;
      -ms-user-select: auto;
      user-select: auto;
    }

    .back-button {
      width: 100px;

      @include breakpoint(large) {
        width: auto;
        display: none !important;
      }
    }

    + .scroll-area {
      max-height: calc(100% - 45px);
    }
  }

  > .scroll-area {
    .scroll-content {
      padding: 1em;
    }
  }

  @include breakpoint(large) {
    border-radius: 1em;
    overflow: hidden;
    max-width: $esp-main-width;
    box-shadow: 0 0.5em 1.5em -0.75em rgba(#000, 0.5);
  }
}

body#os-ios {
  .esp-main-panel {
    height: calc(100vh - #{$carrier-height-ios});

    @include breakpoint(medium) {
      height: 100%;
    }

    @media #{$media-iphone-notch} {
      height: calc(100vh - #{$iphone-notch-safe-bottom});
    }
  }
}

body#os-android {
  .esp-main-panel {
    height: calc(100vh - #{$carrier-height-android});

    @include breakpoint(medium) {
      height: 100%;
    }
  }
}
