@import 'variables';

.CalendarDay {
  border: 1px solid lighten($react-dates-color-border-light, 3);
  padding: 0;
  box-sizing: border-box;
  color: $react-dates-color-gray;
  cursor: pointer;
}

.CalendarDay__button {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  box-sizing: border-box;

  &:active {
    outline: 0;
  }
}

// This order is important.
.CalendarDay--highlighted-calendar {
  background: $react-dates-color-highlighted;
  color: $react-dates-color-gray;
  cursor: default;

  &:active {
    background: $react-dates-color-secondary;
  }
}

.CalendarDay--outside {
  border: 0;
  cursor: default;

  &:active {
    background: $react-dates-color-white;
  }
}

.CalendarDay--hovered {
  background: lighten($react-dates-color-border-light, 3);
  border: 1px double darken($react-dates-color-border-light, 3);
  color: inherit;
}

.CalendarDay--blocked-minimum-nights {
  color: $react-dates-color-gray-lighter;
  background: $react-dates-color-white;
  border: 1px solid lighten($react-dates-color-border-light, 3);
  cursor: default;

  &:active {
    background: $react-dates-color-white;
  }
}

.CalendarDay--selected-span {
  background: $react-dates-color-primary-shade-2;
  border: 1px double $react-dates-color-primary-shade-1;
  color: $react-dates-color-white;

  &.CalendarDay--hovered,
  &:active {
    background: $react-dates-color-primary-shade-1;
    border: 1px double $react-dates-color-primary;
  }

  &.CalendarDay--last-in-range {
    border-right: $react-dates-color-primary;
  }
}

.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: $react-dates-color-primary-shade-4;
  border: 1px double $react-dates-color-primary-shade-3;
  color: $react-dates-color-secondary;

  &:active {
    background: $react-dates-color-primary-shade-3;
  }
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  background: $react-dates-color-primary;
  border: 1px double $react-dates-color-primary;
  color: $react-dates-color-white;

  &:active {
    background: $react-dates-color-primary;
  }
}

.CalendarDay--blocked-calendar {
  background: $react-dates-color-gray-lighter;
  color: $react-dates-color-gray-light;
  cursor: default;

  &:active {
    background: $react-dates-color-gray-lighter;
  }
}

.CalendarDay--blocked-out-of-range {
  color: $react-dates-color-gray-lighter;
  background: $react-dates-color-white;
  border: 1px solid lighten($react-dates-color-border-light, 3);
  cursor: default;

  &:active {
    background: $react-dates-color-white;
  }
}
