.block {
  &[data-template='card01'] {
    max-height: 50vh;
    overflow: hidden;
    position: relative;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 65%;
      background-image: linear-gradient(
        rgba(map-get($demo-colors, primary), 0),
        rgba(map-get($demo-colors, primary), 1)
      );
    }

    .card {
      position: relative;
    }

    .mock {
      position: absolute;

      &.name {
        top: 5%;
        left: 18px;
        font-size: 2rem;
        font-weight: 500;
      }

      &.title {
        top: 51px;
        left: 19px;
        font-size: 1.3rem;
        font-weight: 500;
      }
      &.phone {
        top: 304px;
        left: 57px;
        font-size: 1.2rem;
      }
      &.email {
        top: 258px;
        left: 57px;
        font-size: 1.2rem;
      }
    }

    // Temp Style //
    .ui.card {
      width: calc(100% - 24px);
    }
  }
}
