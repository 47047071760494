.esp-price-field {
  > .field {
    > .ui.action.input {
      border-width-right: 1px;

      > input {
        border-right-color: rgba(0, 0, 0, 0.05) !important;

        &:focus {
          border-right-color: #85b7d9 !important;
        }
      }
    }
    > .ui.button {
      margin-top: 28px;
    }
  }
}
