.esp-workflow {
  display: block;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #fff;

  .workflow-content {
    height: 100%;

    .esp-blocks {
      margin: 0;
    }
  }

  &.menu-top {
    .workflow-content {
      padding-top: map-get($workflow-menu-height, header);
    }
  }

  &.menu-bottom {
    .workflow-content {
      padding-bottom: map-get($workflow-menu-height, footer);
    }
  }

  .ui.menu {
    border: none;
    box-shadow: 0 0 0.375em 0.25em #fff;

    &:after {
      display: none !important;
    }

    &.top {
      justify-content: space-between;
      background: rgba(#fff, 0.1);

      .item {
        padding: 0.5em 0.75em;
      }
    }

    .menu-back {
    }

    .menu-progress {
      flex-grow: 2;
      text-align: center;
    }

    .close-modal,
    .menu-leave {
      .icon {
        font-family: 'Inter';
        line-height: 0.75em;
        font-size: 2em !important;

        &.close {
          opacity: 1 !important;
        }

        &:before {
          content: '\00d7';
        }
      }
    }

    .menu-leave {
      display: flex;

      //      @include breakpoint(large) {
      //        display: none;
      //      }
    }

    .item {
      //color: map-get($demo-colors,primary);

      i.icon {
        font-size: 2em;
        width: 1.18rem;
      }

      &.icon {
        i.icon {
          font-size: 1.25rem;
        }
      }
    }

    a.item {
      background: none;
    }
  }

  > .ui.fixed.menu {
    background: #fff;
    position: absolute;

    .menu-next {
      display: inline-block;
      min-width: 33%;
      margin: 0;
    }

    .item.center.aligned {
      text-align: center;

      + .item.center.aligned {
        padding-top: 0;
      }
    }
  }
}

.esp-main > .esp-workflow {
  background: #fff;

  @include breakpoint(medium) {
    border-radius: 1em;
    overflow: hidden;
  }
}

.ui.menu {
  .menu-help {
    display: none !important;
  }
}

body.workflow:not(.app-user):not(.app-admin) {
  .ui.menu {
    .menu-help {
      //  display: none;
    }
  }

  @include breakpoint(medium) {
    background-image: linear-gradient(rgba(#000, 0.2), rgba(#000, 0.6));

    #app {
      border-radius: 1em;
      overflow: hidden;
      box-shadow: 0 1em 3.5em -1em rgba(#000, 0.75);
      background: #fff;
      position: absolute;
      height: $modal-height;
      width: 100%;
      max-width: $modal-width;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

body.two-menu-items {
  .esp-workflow .workflow-content {
    padding-bottom: 102px;
  }
}
