@import '../../../../../scss/v1/vars';

._ {
  text-align: center;
  background-color: map-get($demo-colors, secondary);
  font-size: 12px;
  color: map-get($text-colors, black);
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 900;
}
