// FadeIn - Currently used in conversation bubbles

.esp-fadeIn {
  opacity: 0.01;
  transform: scale(0.95);
}

.esp-fadeIn.appear {
  opacity: 1;
  transform: scale(1);
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
    opacity 500ms ease-in-out;
}

// NOTE: Realizing this needs to be redone as an animation instead of transitions since we need the height/overflow to jump at the end.
