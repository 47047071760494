.block {
  &[data-template='radio01'] {
  }

  &[data-template='radio02'] {
    .checkmark.icon {
      right: 0;
      position: absolute;
      padding-right: 1.5em;
    }
  }

  &[data-template='radio03'] {
    .ui.checkbox {
      position: relative;

      label:before,
      label:after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      label:after {
        transform: translateY(-50%) scale(0.45);
      }
    }
  }
}
