#esp-channels {
  height: 100%;

  .ui.buttons {
    margin: 1rem 1rem 0;
    width: calc(100% - 2rem);

    .ui.button {
      position: relative;
    }
  }

  .scroll-area {
    max-height: calc(100% - 42px - 1rem);

    .scroll-content {
      padding: 1em 0;
    }
  }

  //  .ui.list {
  //
  //    .item {
  //      > .ui.header {
  //        margin-bottom: .25em;
  //        > .icon {
  //          font-size: 1.25em;
  //          + .content {
  //            padding-left: .25em;
  //          }
  //        }
  //      }
  //    }
  //  }
}
