// This has to stay here until the panels vars are abstracted to SUI

.modals.dimmer .ui.scrolling.modal {
  position: absolute !important;
  transform: translate(-50%, -50%);
}

.ui.modal {
  &.lightbox {
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    width: auto;
    margin: 2em auto !important;
    top: 1em;

    @media only screen and (min-width: 768px) {
      transform: translateX(-50%) !important;
    }

    .ui.basic.inverted.right.floated.button {
      box-shadow: none !important;
      padding-right: 1em;
      padding-left: 1em;
      text-transform: none;
      background: none !important;
    }

    > img {
      border-radius: 0 !important;
      margin: 0 auto 1em;
    }
  }

  &.small,
  &.large {
    height: 100%;
    max-height: $modal-height;
    overflow: visible;

    > .scroll-area {
      max-height: calc(100% - #{72px + 46px});

      .esp-blocks {
        position: relative;
        top: calc(#{72px + 46px} - 1em);
      }
    }

    > .actions {
      text-align: center;
    }
  }

  &.large {
    &.scrolling {
      max-width: 720px;
    }
  }

  &.confirm {
    height: initial;

    .confirm-content {
      text-align: left;
      line-height: 1.5;
      display: inline-block;
      padding: 1em;
    }
  }

  .ui.menu {
    .close.icon {
      opacity: 0;
    }
  }

  &.large > .ui.form {
    .header {
      border-radius: 0.75em 0.75em 0 0;
    }
    .actions {
      border-radius: 0 0 0.75em 0.75em;
    }
  }
}

body#os-ios {
  .ui.page.modals.dimmer.active {
    top: $carrier-height-ios;

    .ui.modal:not(.block-modal) {
      > .ui.form,
      > form {
        height: calc(100% - #{$carrier-height-ios});

        @media #{$media-iphone-notch} {
          height: calc(100% - #{$iphone-notch-safe-top});
        }

        @media #{$media-iphone-notch-12-max} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above});
        }
        @media #{$media-iphone-notch-12-pro} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above});
        }
        @media #{$media-iphone-notch-12-mini} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above});
        }
      }

      .content {
        @media #{$media-iphone-notch} {
          height: calc(100% - #{$iphone-notch-safe-bottom + 48px + 71px});
        }
      }
    }

    .ui.modal.block-modal {
      > .ui.form,
      > form {
        .content.withFooter {
          @media #{$media-iphone-notch} {
            height: calc(
              100% - 48px - 71px - #{$iphone-notch-safe-bottom}
            ) !important;
          }
        }
      }
    }

    // This is because our modals are not fucking consistent. The cards still trigger modals from the main panel causing lots of problems.
    .ui.small.scrolling.modal.transition.visible.active {
      > .ui.form.esp-workflow,
      > form.esp-workflow {
        @media #{$media-iphone-notch} {
          height: calc(100% - #{$iphone-notch-safe-top});
        }
      }

      .withFooter {
        @media #{$media-iphone-notch} {
          height: calc(100% - 48px - 71px - #{$iphone-notch-safe-bottom});
        }

        @media #{$media-iphone-6} {
          height: calc(100% - (48px + 71px)) !important;
        }
      }
    }

    @media #{$media-iphone-notch} {
      top: $iphone-notch-safe-top !important;

      .workflow-content + .ui.borderless.bottom.menu {
        padding-bottom: $iphone-notch-safe-bottom;
      }

      .esp-workflow-modal {
        padding-bottom: $iphone-notch-safe-bottom;

        &:after {
          content: ' ';
          position: absolute;
          display: block;
          height: $iphone-notch-safe-bottom + 1px;
          background-color: #fff;
          width: 100%;
        }

        .ui.borderless.bottom.menu {
          bottom: $iphone-notch-safe-bottom;
        }
      }
    }

    @media #{$media-ipad-tier3} {
      top: 0 !important;
    }

    @media #{$media-ipad-tier4} {
      top: 0 !important;
    }

    @media #{$media-ipad-tier5} {
      top: 0 !important;
    }

    @media #{$media-ipad-tier6} {
      top: 0 !important;
    }
  }

  .esp-main > .ui.page.modals.dimmer.active {
    top: 0;
    height: calc(100% - #{$carrier-height-ios});

    @media #{$media-iphone-notch} {
      height: calc(100% - #{$iphone-notch-safe-top});
    }
    @media #{$media-iphone-notch-12-max} {
      height: calc(100% - #{$iphone-notch-safe-top-12-above});
    }
    @media #{$media-iphone-notch-12-pro} {
      height: calc(100% - #{$iphone-notch-safe-top-12-above});
    }
    @media #{$media-iphone-notch-12-mini} {
      height: calc(100% - #{$iphone-notch-safe-top-12-above});
    }
  }

  @media #{$media-iphone-14-pro} {
    .ui.modal.large,
    .ui.modal.small {
      height: 98%;
    }
    .modals.dimmer .ui.scrolling.modal {
      transform: translate(-50%, -44%);
    }

    .esp-main-panel {
      height: calc(100vh - 93px);
    }
    .esp-announcement-manage form {
      height: calc(100% - 83px);
    }
    .esp-main {
      top: 86px;
    }
    form.casemgmt-info {
      height: calc(100% - 136px);
    }
    .esp-casemgmt-detail form.casemgmt-info .scroll-area {
      max-height: calc(100% - 80px);
    }
    .esp-casemgmt-detail .esp-main-panel.esp-chat {
      height: calc(100% - 142px);
    }
  }

  @media #{$media-iphone-12-pro} {
    .esp-main {
      top: $carrier-height-ios-12-pro;
    }

    .ui.small.modal > .content,
    .ui.small.modal > .ui.form > .content,
    .ui.small.modal > form > .content {
      height: calc(100% - 223px) !important;
    }

    .esp-casemgmt-detail .esp-main-panel.esp-chat {
      height: calc(100% - 119px);
    }

    .esp-main-panel {
      height: calc(96vh - 20px);
    }
    .modals.dimmer .ui.scrolling.modal {
      transform: translate(-50%, -46%);
    }
    .esp-main > .ui.page.modals.dimmer.active {
      top: 38px !important;
    }
  }

  @media #{$media-iphone-notch-12-max} {
    .ui.page.modals.dimmer.active {
      top: 0px !important;
    }
  }
  @media #{$media-iphone-notch-12-pro} {
    .ui.page.modals.dimmer.active {
      top: 38px !important;
    }
  }
  @media #{$media-iphone-notch-12-mini} {
    .ui.page.modals.dimmer.active {
      top: 38px !important;
    }
  }
}

body#os-android {
  .ui.page.modals.dimmer.active {
    top: $carrier-height-android !important;

    .ui.modal {
      > .ui.form,
      > form {
        height: calc(100% - #{$carrier-height-android});
      }
    }
  }

  .esp-main > .ui.page.modals.dimmer.active {
    top: 0 !important;
    height: calc(100% - #{$carrier-height-android});
  }
}

body {
  &.editor-page {
    .ui.page.modals.dimmer.active {
      .content {
        height: calc(100% - 123px);
      }
    }
  }
}

body#os-ios[data-os-version^='12'][data-keyboard='open'] {
  // Target any ios 12 >=

  .esp-main-panel {
    &.esp-chat {
      // keyboard fix
      height: 100%; // for not modals

      &.modal {
        // fix only for modals, override it with this
        height: 100%;

        // keyboard fix specifically for iphone x
        @media #{$media-iphone-notch} {
          height: calc(100% - #{$iphone-notch-safe-top}) !important;
        }

        @media #{$media-iphone-notch-12-max} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above}) !important;
        }
        @media #{$media-iphone-notch-12-pro} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above}) !important;
        }
        @media #{$media-iphone-notch-12-mini} {
          height: calc(100% - #{$iphone-notch-safe-top-12-above}) !important;
        }

        @media #{$media-ipad-tier3} {
          height: 100% !important;
        }

        @media #{$media-ipad-tier4} {
          height: 100% !important;
        }

        @media #{$media-ipad-tier5} {
          height: 100% !important;
        }

        @media #{$media-ipad-tier6} {
          height: 100% !important;
        }
      }
    }

    &.esp-casemgmt-detail {
      &.case-conversation {
        height: calc(100% - 105px) !important;
      }
    }
  }
}
