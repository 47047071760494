//Vendor
@import 'v1/vendor/animate';
@import 'v1/vendor/toastr';
@import 'v1/vendor/highlight';

// Utils
@import 'v1/utils/value';
@import 'v1/utils/unit';
@import 'v1/utils/breakpoint';
@import 'v1/vars';
@import 'v1/typography';
@import 'v1/animations';
@import 'v1/icons';

// Elements
@import 'v1/elements/esp-hero';
@import 'v1/elements/esp-catalog-detail';
@import 'v1/elements/esp-equipment-list';
@import 'v1/elements/esp-equipment-detail';
@import 'v1/elements/esp-announcement-list';
@import 'v1/elements/esp-announcement-manage';
@import 'v1/elements/esp-grid-item';
@import 'v1/elements/esp-product-images';
@import 'v1/elements/esp-service-team-images';
@import 'v1/elements/esp-categories';
@import 'v1/elements/esp-footer';
@import 'v1/elements/esp-search';
@import 'v1/elements/esp-form';
@import 'v1/elements/esp-scroll';
@import 'v1/elements/esp-tenant-preview';
@import 'v1/elements/esp-panels';
@import 'v1/elements/esp-main-panel';
@import 'v1/elements/esp-product-header';
@import 'v1/elements/esp-price-field';
@import 'v1/elements/esp-section-title';
@import 'v1/elements/esp-workflow';
@import 'v1/elements/chat-text';
@import 'v1/elements/chat-bubble';
@import 'v1/elements/chat-input';
@import 'v1/elements/menu-toggle';
@import 'v1/elements/cart';
@import 'v1/elements/progress';
@import 'v1/elements/snackbar';
@import 'v1/elements/modal';
@import 'v1/elements/workflow-modal';
@import 'v1/elements/onboard-workflow';
@import 'v1/elements/onboarding-activity-template-list';
@import 'v1/elements/segment-card';
@import 'v1/elements/esp-full-search-results';
@import 'v1/elements/_esp-escalate';
@import 'v1/elements/_editor-page';
@import 'v1/elements/_edit-profile';
@import 'v1/elements/_esp-select-user';
@import 'v1/elements/_esp-onboarding-activities';
@import 'v1/elements/_esp-casemgmt-detail';
@import 'v1/elements/_esp-feedback-help';

// Organisms
@import 'v1/elements/admin-section';
@import 'v1/elements/esp-admin-nav';
@import 'v1/elements/esp-cards';
@import 'v1/elements/esp-channels';
@import 'v1/elements/esp-chat';
@import 'v1/elements/esp-directory';
@import 'v1/elements/esp-nav';
@import 'v1/elements/esp-profile';
@import 'v1/elements/esp-settings';
@import 'v1/elements/esp-slider';
@import 'v1/elements/esp-about-me';

// Blocks
@import 'v1/blocks/addresponses';
@import 'v1/blocks/background';
@import 'v1/blocks/barista';
@import 'v1/blocks/block-modal';
@import 'v1/blocks/bundle-select';
@import 'v1/blocks/button';
@import 'v1/blocks/card';
@import 'v1/blocks/cart';
@import 'v1/blocks/conversation';
@import 'v1/blocks/field';
@import 'v1/blocks/hero';
@import 'v1/blocks/imageSelect';
@import 'v1/blocks/loading';
@import 'v1/blocks/logo';
@import 'v1/blocks/preview';
@import 'v1/blocks/product-configuration';
@import 'v1/blocks/radio';
@import 'v1/blocks/reset-password';
@import 'v1/blocks/skills';
@import 'v1/blocks/softwareSelect';
@import 'v1/blocks/spacer';
@import 'v1/blocks/system-avatars';
@import 'v1/blocks/text';
@import 'v1/blocks/textarea';
@import 'v1/blocks/user-select';
@import 'v1/blocks/work-address';
@import 'v1/blocks/select-my-gear';
@import 'v1/blocks/select-user-contact-group';
@import 'v1/blocks/birthday';
@import 'v1/blocks/image';
@import 'v1/blocks/phoneNumber';
@import 'v1/blocks/addresponsetext';
@import 'v1/elements/esp-blocks';

// AirBnB Dates
@import 'v1/dates/styles.scss';
@import 'v1/dates/SingleDatePickerInput.scss';
@import 'v1/dates/SingleDatePicker.scss';
@import 'v1/dates/DayPickerNavigation.scss';
@import 'v1/dates/DayPickerKeyboardShortcuts.scss';
@import 'v1/dates/DayPicker.scss';
@import 'v1/dates/DateRangePickerInput.scss';
@import 'v1/dates/DateRangePicker.scss';
@import 'v1/dates/DateInput.scss';
@import 'v1/dates/CalendarMonthGrid.scss';
@import 'v1/dates/CalendarMonth.scss';
@import 'v1/dates/CalendarDay.scss';

body {
  letter-spacing: 0.01em;
}

.visible.transition.inline {
  display: inline-block !important;
}

.visible.transition.flex {
  display: flex !important;
}

.ui.comments {
  .comment {
    > .avatar {
      /* IE11 Fixes */
      @media all and (-ms-high-contrast: none) {
        *::-ms-backdrop,
        svg {
          height: 2.5em;
        }
      }
    }

    a {
      text-decoration: underline;
    }

    &.me {
      .content {
        .text a {
          color: #fff;
        }
      }
    }
  }
}

.ui.list + .ui.button {
  margin-top: 1em;
}
[data-component='ListCardFront'] .ui.button.survey-button {
  &:hover,
  &:active,
  &:focus,
  &.selected,
  &.active {
    color: #1a1a1a !important;
  }
}

.white-space {
  white-space: pre-line;
}

div.user-select {
  height: 100%;

  .ui.buttons {
    margin: 0.75rem 0.75rem 0;
    width: calc(100% - 1.5rem);
  }

  .scroll-area {
    max-height: calc(100% - 54px);
  }
}

.g-recaptcha {
  display: inline-block;
}

.hidden:not(.divider) {
  display: none;
}

// Search Match

.match {
  font-weight: 700;
  text-decoration: underline;
}

body.login-page,
body.reset-password-page {
  padding: 0.5em;
  transition: background 500ms ease;

  &.tenant-select {
    background-color: map-get($demo-colors, primary) !important;

    .ui.button.primary {
      background-color: map-get($demo-colors, primary) !important;
      color: #fff !important;
    }
  }

  @include breakpoint(450px) {
    padding: 1em;
  }

  .app-root > .grid {
    height: 100%;
    overflow-y: auto;
  }

  .column {
    max-width: 450px;
  }

  .app-root .ui.image.logo {
    max-width: 65%;
    margin: 0 auto;
  }

  .ui.segment {
    padding: 0.75em;

    @include breakpoint(450px) {
      padding: 1em;
    }
  }

  .ui.right.labeled.input {
    margin-bottom: -0.25em;

    @include breakpoint(450px) {
      margin-bottom: 0;
    }

    input[name='tenantName'] {
      width: calc(100% - 40vw);

      @include breakpoint(450px) {
        width: auto;
      }
    }

    .ui.basic.label {
      width: 40vw;
      font-weight: 400;
      font-size: 0.875em;
      text-align: center;

      @include breakpoint(450px) {
        width: auto;
        font-size: 1em;
      }
    }
  }
}

.ui.input {
  input[type='date'],
  input[type='time'],
  input[type='text'] {
    min-height: 2.375rem;
  }
}

.field.file {
  cursor: pointer;

  .ui.input {
    border: 1px solid #f1f1f1;
    border-radius: 2px;

    &:hover {
      border-color: darken(#f1f1f1, 12%);

      > label > .ui.button {
        background-color: darken(#f1f1f1, 12%);
      }
    }

    &:active {
      background-color: lighten(#f1f1f1, 2%);
    }

    .filename,
    .placeholder {
      display: inline-block;
      padding: 0 0.75em;
      min-width: 160px;
      cursor: pointer;
    }

    .placeholder {
      opacity: 0.5;
    }

    > label {
      margin: -1px;

      > .ui.button {
        margin-right: 0;
      }
    }
  }
}

label.hidden {
  visibility: hidden;
}

// PANEL MAIN

.esp-main {
  @include breakpoint(medium) {
    perspective: 800px;
    perspective-origin: 50% 50%;
  }
}

.panel-main-enter {
  @include breakpoint(large) {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translateX(2em) translateZ(0) rotateY(0);
    opacity: 0.01;
    position: relative;
    z-index: 10;
  }
}

.panel-main-enter.panel-main-enter-active {
  @include breakpoint(large) {
    transform: translateX(0) translateZ(0) rotateY(0deg);
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
}

.panel-main-leave {
  opacity: 1;

  @include breakpoint(large) {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translateX(0) translateY(-100%) translateZ(0) rotateY(0deg);
    position: relative;
    z-index: 1;
  }
}

.panel-main-leave.panel-main-leave-active {
  opacity: 0.01;
  transition: all 200ms ease-in-out;

  @include breakpoint(large) {
    transform: translateX(-2em) translateY(-100%) translateZ(-200px)
      rotateY(30deg);
  }
}

// PANEL SECONDARY

.panel-secondary-enter {
  opacity: 0.01;
  position: relative;
  z-index: 10;
}

.panel-secondary-enter.panel-secondary-enter-active {
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.clearfix:after {
  content: ' ';
  display: table;
  clear: both;
}

// Resolve issue with native ms edge icons in input fields

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.char-counter {
  margin: -3em 0 0;
  float: right;
  position: relative;
  z-index: 1;
  padding-right: 0.5em;
  font-size: 0.875em;
  opacity: 0.7;
}

.field {
  .char-counter {
    margin: -1.8em 0 0;
  }
}

.ui.modal > .ui.form.pick-location .field > .selection.dropdown {
  display: flex;
  > div.text {
    width: 100%;
  }
}

// SUI Inline form fields
.ui.form .field.inline {
  label {
    max-width: 30%;
  }
  .ui.fluid.input {
    display: inline-block;
    width: calc(70% - 0.875em) !important;
    > input {
      width: 100% !important;
    }
  }
}

.ui.button.loading {
  pointer-events: none !important;
}

.ui.list .image-container > .ui.image {
  height: 4em;
}

@media (orientation: landscape) {
  body#web.no-content.esp.login-page.tenant-select
    div#app
    div.app-root
    div.ui.centered.middle.aligned.grid
    div.row
    div.column
    div.ui.segment {
    width: 110%;
  }

  body#os-android.no-content.esp.login-page.tenant-select
    div#app
    div.app-root
    div.ui.centered.middle.aligned.grid
    div.row
    div.column
    div.ui.segment {
    width: 110%;
  }
}

body#os-ios {
  #kb_modal {
    .scroll-area {
      height: calc(100% - 20px) !important;
    }

    @media only screen and (-webkit-device-pixel-ratio: 3) and (device-height: 812px) and (device-width: 375px) {
      .scroll-area {
        height: calc(100% - 44px) !important;
      }
      #IFrameModal {
        padding-bottom: 2rem;
      }
    }
  }

  .esp-secondary .esp-case-management [data-component='SliderItem'] {
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) {
      height: calc(100vh - 351px);
    }
  }
}

#kb_modal.white-background {
  background-color: #fff !important;
}
:-webkit-full-screen-ancestor .barista-chat #app {
  display: none;
}

[data-component='SliderItem'] {
  height: 100%;
}

.esp-secondary .esp-case-management [data-component='SliderItem'] {
  @media (min-width: 1024px) {
    height: calc(100vh - 320px);
  }
  @media (min-width: 768px) {
    height: calc(100vh - 320px);
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(100vh - 310px);
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    height: calc(100vh - 310px);
  }
}
