// https://developer.mozilla.org/en-US/Firefox/Releases/57
// calc is not working in firefox 57
// Firefox media query
@-moz-document url-prefix() {
  .onboarding-activity-template-list {
    .scroll-area {
      position: absolute !important;
    }
  }
}
