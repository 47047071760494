.esp-chat {
  .ui.menu {
    margin: 0;

    .close-button {
      @include breakpoint(large) {
        display: none;
      }
    }
  }

  > .barista.actions {
    text-align: center;
    background: #e0e1e2;
    padding: 0.75em;
    margin: 0;
    border-top: 1px solid rgba(#000, 0.15);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 10;

    .ui.button.basic {
      background-color: #fafafa !important;
      margin-bottom: 0;
      margin-left: 0.5em;
      margin-right: 0.5em;

      &:hover,
      &:focus {
        background-color: #fff !important;
      }
    }
  }

  > .scroll-area {
    height: calc(100% - 90px) !important;

    // // Hackery way for iPhone X > Moved to panels because this garbage should be in one place for now
    // @media only screen
    // and (device-width : 375px)
    // and (device-height : 812px)
    // and (-webkit-device-pixel-ratio : 3) {
    //   max-height: calc(100% - 164px - 100px - 1em) !important;
    // }

    > .scroll-content {
      padding: 0 1em 1.5em;
    }
  }

  form.reply.chat-input {
    .send-button {
      margin: -0.5em !important ;
      padding: 0.5em 0.75em !important;
    }

    .file {
      margin: -0.5em;
      > .ui.input {
        border: none;
      }
      > .ui.input:active {
        background-color: inherit;
      }
    }

    .attach-icon {
      margin: 0em 0.7em -0.5em 0 !important;
      padding: 0.55em 0.75em !important;
    }
  }

  .ui.reply.form {
    padding-left: 1em;
    padding-right: 1em;
    position: fixed;
    bottom: 1em;
    width: 100%;

    @include breakpoint(medium) {
      position: absolute;
    }

    .ui.segment {
      max-width: 100%;
      margin: 0;

      &.top {
        textarea {
          max-height: 300px;
          overflow-y: scroll;
        }
      }
    }
  }

  @include breakpoint(medium and large) {
    max-width: $esp-main-width;
  }

  @include breakpoint(large) {
    background: #fff;
    border-radius: 1em;
    overflow: hidden;
    max-width: $large-esp-main-width;
    box-shadow: 0 0.5em 1.5em -0.75em rgba(#000, 0.5);
  }

  .ui.comments {
    .comment.barista {
      > .avatar {
        img {
          border-radius: none;
        }
      }
    }

    .comment {
      > .avatar {
        /* IE11 Fixes */
        @media all and (-ms-high-contrast: none) {
          *::-ms-backdrop,
          svg {
            height: 2.5em;
          }
        }
      }

      &.image {
        > .avatar {
          &:after {
            display: none;
          }
        }

        > .content {
          padding: 0;
          cursor: pointer;

          > .text {
            img {
              border-radius: 0.25em;
            }
          }
        }
      }
    }

    .ui.divider {
      font-weight: 400;
      text-transform: none;
      color: #999;
      font-size: 0.75em;
      white-space: normal;
      width: 100%;
      display: flex;

      > .message {
        max-width: 90%;
        width: 100%;
        flex-grow: 2;
      }

      &:before,
      &:after {
        display: block;
        flex-shrink: 1;
        width: 20%;
      }
    }
  }
}

.ui.modal {
  .item.close {
    display: block;
    color: map-get($text-colors, default-color);
    padding: 0.9375em 1.125em;
    font-size: 1rem;
    top: 1px !important;
    width: auto;
    height: auto;
  }

  .esp-chat {
    .ui.menu {
      .close-button {
        display: none;
      }
    }
  }
}

body#os-ios {
  .esp-chat {
    .scroll-area {
      // height: calc(100% - 91px - env(safe-area-inset-bottom)) !important;
    }
  }
}

body#os-android {
  .esp-chat {
    .ui.reply.form {
    }
  }
}
// modify scroll area height when impersonation bar is present
#impersonation_bar + .scroll-area {
  height: calc(90% - 92px) !important;
}

@media #{$media-iphone-notch} {
  #ChatForm {
    padding-top: 5px !important;
    padding-bottom: 15px !important;
  }
  #ChatForm:focus-within {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.scroll-content {
  overflow-x: auto !important;
}
