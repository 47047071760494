.esp-blocks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  padding-bottom: 1em;

  .block {
    width: 100%;
    padding: 0 1em;

    /* IE11 Fixes */
    @media all and (-ms-high-contrast: none) {
      flex: 0 0 auto;
    }

    + .block {
      padding-top: 1em;
    }

    &:first-of-type {
      padding-top: 1em;
    }

    &:last-of-type {
      padding-bottom: 1em;
    }

    &.centered {
      text-align: center;
    }
  }

  &.empty {
    max-width: $esp-main-width;
  }
}

body {
  &.blocks-justify {
    .esp-blocks {
      justify-content: space-between;
    }
  }

  &.blocks-top,
  &.blocks-justify.blocks-top {
    .esp-blocks {
      justify-content: flex-start;
    }
  }
}
