body.editor-page {
  .editorConditions {
    .field {
      margin-top: 1em;

      label {
        color: map-get($text-colors, default-color);
      }
    }
  }
}
