.esp-hero {
  padding: 0.5em 1.25em 1em;
  position: relative;
  display: list-item;
  justify-content: space-between;
  min-height: 10em;
  z-index: 200;
  list-style: none;

  &.small {
    min-height: 0;
    font-size: 11px;
    padding: 1.5em 1em !important;

    // .content {
    //   max-width: calc(100% - 4.5em);
    // }

    .name {
      font-size: 1.625em;
    }

    .title {
      font-size: 1.125em;
    }

    .group {
      font-size: 1em;
    }

    .avatar {
      > .ui.image,
      > img {
        width: 1.5em;
        height: 1.5em;
      }
    }

    @include breakpoint(xlarge) {
      font-size: 14px;
    }
  }

  // &:not(.stacked) {
  // padding-left: 3em;
  //
  // @include breakpoint(large) {
  // padding-left: 1.25em;
  // }
  // }

  > .action {
    align-self: flex-start;
    margin-left: 0.5em;

    .ui.dropdown {
      color: #fff;
      padding: 0.375em 0.625em;
      margin-top: -0.25em;
      background: transparent;

      i.icon {
      }

      > .menu {
        top: -1.625rem;
        right: -1.125rem;
        left: auto;
        width: map-get($esp-menu-width, small);
        padding-bottom: 0.5em;

        @include breakpoint(xlarge) {
          width: calc(#{map-get($esp-menu-width, large)});
        }
      }
    }
  }

  > .content,
  > .avatar,
  > .action,
  > .avatar-link,
  > .empty-avatar {
    z-index: 10;
  }

  > .content {
    max-width: calc(100% - 6.5em);
  }

  .avatar-link {
    transition: transform 200ms ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    &.disabled {
      pointer-events: none;
      transform: none !important;
    }
  }

  .content {
    color: #fff;
    display: table-cell;
    vertical-align: middle;
    width: 100%;

    > * {
      line-height: 1.2em;
      margin: 0;
    }

    .name,
    .title,
    .group {
      text-shadow: 0 0 1em rgba(#000, 0.5);
    }

    .name {
      margin-bottom: 0.125em;
      word-wrap: break-word;
    }

    .title {
    }

    .group {
    }

    .ui.buttons {
      margin-top: 10px;
      border: none;

      .ui.button,
      .ui.button:hover {
        box-shadow: none !important;
        padding: 0.5em;
      }
    }
  }

  .avatar {
    display: table-cell;
    vertical-align: middle;

    > .ui.image,
    > img {
      display: inline-block;
      font-size: 3em;
      border-radius: 50%;
      width: 2em;
      height: 2em;
      border: 0.07em solid #fff;
      box-shadow: 0 0 1.5em rgba(#000, 0.25);
    }
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;

    .scrim {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(#000, 0.2) 30%, rgba(#000, 0.4));
      z-index: 2;
    }

    img {
      position: absolute;
      width: 101%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}

.empty-avatar {
  width: 2em;
  height: 2em;
  background: #ccc;
  border-radius: 4em;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  line-height: 2em;
  color: #fff;
}
