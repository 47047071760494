.block {
  &[data-template='skills01'] {
    padding-left: 32px;

    h2 {
      text-align: center;
    }

    span {
      margin-left: 30px;
    }
  }
  .input-wrapper {
    width: 100%;
  }
  .item-radio {
    .item-inner {
      margin: 5px 0px 0px 15px !important;
    }
  }
}
