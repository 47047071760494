.esp-blocks .block {
  &[data-template='birthday01'] {
    font-size: 16px;

    .field + .field {
      padding-top: 1.25rem;
    }
  }
}

body.onboard-workflow {
  .block[data-template='birthday01'] {
    .field + .field {
      padding-top: 1.625rem;
    }
  }
}
