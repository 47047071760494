.esp-admin-onboarding-activities {
  .ui.table {
    .performance-list {
      .performance-user {
        > a {
          .ui.image {
            width: 4em;
          }
        }
      }
    }
  }
}
