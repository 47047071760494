.formCheckbox {
  width: 100%;
}

.field {
  margin-bottom: 1em;
  margin-left: 2em;
  width: calc(100% - 2em);
}

.dropradio {
  margin-bottom: 2em;
  margin-left: 2em;
  width: calc(100% - 2em);
}
