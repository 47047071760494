.esp-product-header {
  display: flex;
  justify-content: space-between;

  .product-image {
    max-width: 6em;

    + .title {
      padding-left: 1em;
    }
  }

  .title {
    flex-grow: 2;
  }

  .ui.header {
    margin: 0;
    padding-right: 0.5em;
  }
}

.ui.list > .item {
  .esp-product-header {
    .ui.header {
      font-size: 1em;
    }

    + .details {
      margin-top: 0.5em;
    }
  }
}

.esp-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;

  .meta {
    .notification {
      font-size: 0.75em;
    }

    .price + .notification {
      margin-left: 1em;
    }
  }
}
