.block {
  &[data-template='logo_1'],
  &[data-template='logo02'] {
    text-align: center;

    * + * {
      margin-top: 0.25em;
    }

    .text {
    }

    .logo {
      width: 250px;
      max-width: 40%;
    }
  }

  &[data-template='espressive_1'] {
    text-align: center;

    .text {
      font-size: 0.9em;
    }

    .logo {
      width: 100px;
      max-width: 35%;
    }
  }
}
