.esp-workflow-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 250;

  .block-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;

    .ui.fixed.menu {
      position: absolute;

      .header.item {
        font-size: 1.125em;
        font-weight: 400;
        padding-left: 1rem;
      }
    }

    .modal-content {
      margin-top: 46px;
      height: calc(100% - 46px);
    }

    .esp-blocks {
      justify-content: space-between;
      margin: -1em;
      height: calc(100% + 2em);
    }
  }
}
