.container {
  align-items: center;
  display: flex;
}

.image {
  flex: 0.4;
  margin-right: 1em;
}

.categoryName {
  flex: 2;
  margin: 0;
}
