.segment-card {
  display: flex;
  align-items: center;

  > .ui.button,
  > .ui.segment {
    display: table-cell;
  }

  > .ui.segment {
    width: 100%;
  }
}
