._ {
  font-size: 2.5em;
  display: inline-block;
  margin: -0.25rem 0.25rem;
  position: relative;
  vertical-align: middle;

  :global .ext {
    text-transform: uppercase;
    font-size: 0.25em;
    position: absolute;
    text-align: center;
    top: 2.4em;
    width: 100%;
  }

  :global i.icon {
    height: auto;
  }
}
