._ {
  cursor: pointer;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  color: #008468;
  border: none;
  background-color: transparent;
}
