.block {
  &[data-template='selectMyGear01'] {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .product-step,
    .product-family-step {
      padding: 1em 1em 0 1em;
    }

    [data-component='ConversationFeed'] {
      padding: 0 1em;
    }
  }
}
