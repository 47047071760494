#esp-directory {
  position: fixed;
  width: 100%;
  height: 100%;
  position: relative;

  // & > :not(.scroll-area) {
  //   margin: .75rem .75rem 0;
  //   width: calc(100% - 1.5rem);
  // }

  .ui.horizontal.divider {
    display: block;
    background: map-get($demo-colors, primary);
    background: linear-gradient(
      to right,
      map-get($demo-colors, primary) 10%,
      #fff 90%
    );
    text-align: left;
    color: #fff;
    font-weight: 400;
    border-radius: 1em;
    padding: 0.125em 1em 0.25em;
    line-height: 1;
    margin: 0.5rem 0.75rem;

    &:before,
    &:after {
      display: none;
    }
  }

  .scroll-area {
    max-height: calc(100% - 53px);
    max-width: calc(100% - 2rem);

    .scroll-content {
      padding: 0.5em 0;
    }
  }

  > .alphabet {
    font-size: 0.875em;
    line-height: 0;
    height: 100%;
    max-height: calc(100% - 53px);
    padding: 0.5em 0;
    position: absolute;
    text-align: center;
    margin: 0;
    right: 0;
    width: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
    align-content: stretch;
    z-index: 9; // Needs to be below the dropdown filter
  }
}

#esp-directory-search {
  .icon {
    color: #fff;
    opacity: 0.9;
  }

  input {
    background: map-get($demo-colors, primary);
    border-color: map-get($demo-colors, primary);
    color: #fff;

    &::placeholder {
      color: #fff;
      opacity: 0.3;
      transition: opacity 200ms ease-in-out;
    }

    &:focus {
      &::placeholder {
        opacity: 0.7;
      }
    }
  }
}
