._ {
  :global(.ui.button) {
    margin: 0;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    width: 50%;
    margin: 0 auto;
    button {
      width: 10em;
    }
  }
}
