.block {
  &[data-template='conversation01'] {
    &:first-of-type {
      padding-top: 1em;
    }

    .ui.comments {
      .comment {
        @for $i from 1 through 15 {
          &:nth-of-type(#{$i}) {
            transition-delay: (($i - 1) * 1500ms);
          }
        }

        .author,
        .metadata {
          display: none;
        }

        .text {
          margin-top: 0;
        }
      }
    }
  }
}

body {
  &.conversation01 {
    .esp-blocks {
      justify-content: space-between;
    }
  }
}
