body.workflow-modal {
  .esp-main {
    background: none;
    position: fixed !important;
    width: auto !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 250;

    transition: none;
  }

  .ui.modal.small {
    //margin: 0 0 0 -$modal-width/2;

    @include breakpoint(768px) {
      transform: translate(-50%, -50%);
      border-radius: 1em;
      overflow: hidden;
      margin: 0 !important;
    }
  }
}
