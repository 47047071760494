.esp-grid-item {
  padding: 1em;
  box-shadow: inset -1px -1px 0 0px #f1f1f1;

  .ui.ribbon.label {
    float: right;
    left: 3px;
    transform: translateX(calc(1em));

    + .ui.header {
      margin-top: 0;
    }
  }

  > * + * {
    margin-top: 0.5rem;
  }

  > .ui.header {
    font-size: 1.125em;
    display: block;
    margin: 0;
    word-wrap: break-word;
  }

  .description,
  .meta {
    font-size: 0.875em;
    line-height: 1.25em;
  }

  .description {
    color: #333;
    word-wrap: break-word;
  }

  .meta {
    color: #999;
  }

  .item-row {
    display: flex;

    .image-container {
      & + .item-content {
        padding-left: 0.5em;
      }
    }
  }

  .image-container {
    max-width: 30%;
  }

  &.square {
    position: relative;
    padding: 0;

    &:after {
      content: ' ';
      display: block;
      padding-bottom: 100%;
    }

    .item-row,
    .image-container {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      .image-container-name {
        position: absolute;
        bottom: 5px;
        width: 100%;
      }
    }

    .image-container > .ui.image {
      margin: 1px;
      max-width: calc(100% - 2px);
      max-height: calc(100% - 2px);
    }

    &.link {
      &:hover {
        border: 1px solid #8080807a;
      }
    }
  }

  .item-content {
    .ui.label {
      margin-left: 0;

      & + .ui.label {
        margin-top: 0.5em;
      }
    }
  }

  &.selectable {
    > .select-action {
      float: right;

      + .ui.header {
        margin-top: 0;
      }
    }
  }

  &.link {
    position: relative;
    z-index: 2;

    &:hover {
      background-color: #fafafa;
      transition: background 200ms ease;
      z-index: 1;
    }
  }

  &.hub_config {
    position: absolute;
    top: 0;
    display: none;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.8);
    }
  }
}

.esp-grid {
  box-shadow: inset 1px 1px 0 0px #f1f1f1;
}
