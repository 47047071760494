.block {
  &[data-template='userSelect01'] {
  }
}

//styles for BlockModal opened from UserSelect01
.esp-workflow-modal.userSelect01 {
  > .block-modal > .modal-content > .esp-blocks {
    .block:nth-child(2) {
      height: calc(100% - 65px);
    }
  }
}
