.Editor {
  background-color: #fafafa;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.125rem;
  padding: 0.5rem;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.Editor:focus {
  border-color: #85b7d9;
}

.Tag {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 100, 0.1);
  border-radius: 0.25em;
  color: inherit;
  padding: 0.125em 0.25em;
}
