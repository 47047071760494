.barista_404 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  opacity: 1 !important;
  transform: scale(1) !important;
  transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
    opacity 500ms ease-in-out;
}
svg.primary.barista_404.icon {
  max-width: 18%;
}
.barista_404_bubble {
  line-height: 1.375em;
  display: inline-block;
  padding: 0.75em;
  background: #d3f4ef;
  border-radius: 2px;
  max-width: 85%;
  position: relative;
}
.barista_404_bubble:after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  border-bottom: 2em solid #d3f4ef;
  position: absolute;
  top: -1.25em;
  transform: rotate(45deg);
}
@media screen and (min-width: 40em) {
  svg.primary.barista_404.icon {
    max-width: 28%;
  }
  .barista_404_bubble {
    font-size: 1.25em;
  }
}
