.esp-announcement-list {
  position: relative;
  width: 100%;
  height: 100%;
  //  background: linear-gradient(to bottom,lighten(#443323,5%),#443323 50%);

  > .scroll-area {
    .scroll-content {
      > .ui.button {
        margin: 1em;
        max-width: calc(100% - 2em);
      }

      .ui.accordion {
        .title {
          .ui.header {
            padding-left: 0.25rem;
            padding-right: 0.5rem;
          }
        }

        > .content {
          text-align: center;

          > .ui.list {
            text-align: left;
          }
        }
      }

      .ui.list {
        .item {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .content {
          max-width: 50vw;
          word-wrap: break-word;
        }
      }
    }
  }
}

.AnnouncementCategorySelectList > .item > .content .description {
  padding-left: 1rem !important;
  @include breakpoint(large) {
    padding-left: 0 !important;
  }
}
