.esp-settings {
  // TEMP STYLE //
  .ui.form {
    img {
      margin-bottom: 20px;
      box-shadow: 0px 3px 10px -1px rgba(#000, 0.4);
      font-size: 30px;
    }

    .ui.button {
      overflow: hidden;
      position: relative;

      .icon {
        display: inline-block !important;
        cursor: pointer;
      }
    }

    .ui.button [type='file'] {
      cursor: inherit;
      display: block;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      font-size: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
    }

    .ui.button [type='file'] {
      cursor: pointer;
    }
  }
}
