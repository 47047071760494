._ {
  & > :global(.action) {
    & > :global(.ui.button) {
      margin-right: 0.5em;
    }
    float: right;
    transition-property: opacity, transform;
    transition-duration: 200;
    transition-timing-function: 'ease-in-out';
  }
  & > :global(.label) {
    border-radius: 2px;
    line-height: 1em;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    padding: 0.625em 0.5em;
    transition-property: background-color;
    & .text::selection {
      background-color: transparent;
    }
    &:hover {
      background-color: #e0e1e2;
    }
  }
  display: table;
  overflow: hidden;
  padding: 0.5em;
  position: relative;
  width: 100%;
}
