.block {
  &[data-template='background_1'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;

    .scrim {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: opacity 500ms ease-in-out;

      &.black {
        background-color: #000;
      }

      &.white {
        background-color: #fff;
      }
    }

    .bg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      max-width: none;
      height: 100%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

body {
  &.background_1 {
    color: #f1f1f1;
    text-shadow: 0 0 0.5em rgba(#000, 0.5);

    .ui.menu.fixed {
      box-shadow: none;
      background: rgba(#fff, 0.1);

      &.bottom {
        background: linear-gradient(rgba(#fff, 0), rgba(#fff, 0.2));
      }
    }

    .ui.form {
      .field {
        > label {
          color: #f1f1f1;
        }
      }
    }
  }
}
