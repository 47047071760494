.block {
  &[data-template='addResponses01'] {
    .field {
      .ui.attached.segment {
        .ui.list > .item {
          padding: 0.5em 0em;
        }
      }
    }
    .ui.bottom.attached.segment {
      .ui.list > .item {
        white-space: pre-wrap;

        -ms-word-break: break-all;
        word-break: break-all;

        /* Non standard for WebKit */
        word-break: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
      }
    }
  }
}
