.block {
  &[data-template='spacer01'] {
    padding-bottom: 0 !important;
  }
  &[data-template='spacer02'] {
    flex-grow: 2;
    height: 2rem;
    padding-bottom: 0 !important;
  }
}
