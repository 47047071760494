img.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
}

img.profile-pic.left {
  left: 10px;
}

img.profile-pic.right {
  right: 10px;
}

.message-wrapper {
  position: relative;
}

.message-wrapper:last-child {
  margin-bottom: 10px;
}

.message-detail {
  white-space: nowrap;
  font-size: 14px;
}

.chat-bubble {
  border-radius: 5px;
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  margin: 10px;
  max-width: 80%;
}

.chat-bubble:before {
  content: '\00a0';
  display: block;
  height: 16px;
  width: 9px;
  position: absolute;
  bottom: -7.5px;
}

.chat-bubble.left {
  background-color: #e6e5eb;
  color: black;
  float: left;
  margin-left: 55px;
}

.chat-bubble.left:before {
  background-color: #e6e5eb;
  left: 10px;
  -webkit-transform: rotate(70deg) skew(5deg);
}

.chat-bubble.right {
  background-color: #158ffe;
  color: #fff;
  float: right;
  margin-right: 55px;
}

.chat-bubble.right:before {
  background-color: #158ffe;
  right: 10px;
  -webkit-transform: rotate(118deg) skew(-5deg);
}

.chat-bubble.right a.autolinker {
  color: #fff;
  font-weight: bold;
}

.cf {
  clear: both !important;
}

.bold {
  font-weight: bold;
}
