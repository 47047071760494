.block {
  &[data-template='imageSelect01'] {
    text-align: center;

    .image-select {
      > canvas {
        max-width: 100%;
      }
    }
  }
}

//Temp style for canvas
.image-select {
  > canvas {
    width: 100% !important;
    height: auto !important;
  }
}
