.esp-service-team-images {
  position: relative;

  > .ui.label.ribbon {
    position: absolute;
    left: -0.75em;
    top: 0.75em;
    z-index: $menu-zindex - 1;
  }

  .image-container {
    position: relative;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05) inset;
    transition: box-shadow 400ms ease;

    > .ui.image,
    > .ui.button,
    > .ui.image img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
    }

    .ui.dimmable.image {
      height: 100%;
    }

    > .ui.image {
      transition: opacity 200ms ease;
      max-height: 100%;
    }

    > .ui.button {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: ' ';
      display: block;
      padding-bottom: 100%;
    }
  }
}
