.block {
  &[data-template='workAddress01'] {
    .ui.segment.link {
      .content {
        .location {
          max-width: 90%;
          display: inline-block;
        }
      }
      .icon.right.floated {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.3em;
      }
    }
    .readOnly {
      margin-bottom: 0;
    }
  }
}

//styles for BlockModal opened from WorkAddress01
.esp-workflow-modal.workAddress01 {
  > .block-modal > .modal-content .esp-blocks {
    //display: block;
  }
}
