.esp-escalate {
  height: 100%;

  .escalate-details {
    height: calc(100% - 46px - 68px);
  }

  .scroll-content {
    padding: 1em;
  }
}
