.esp-progress {
  :not(.bar) {
    padding: 0.5em;
  }

  &.bar {
    width: 10em;
    max-width: 30vw;
    display: block;
    margin: auto;
    height: 0.4em;
    background: rgba(#000, 0.2);
    border-radius: 2em;
    overflow: hidden;

    .progress {
      transition: width 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
      background: map-get($demo-colors, primary);
    }
  }
}
