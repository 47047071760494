.esp-announcement-manage {
  // max-width: 100%;
  // width: 100%;
  // height: 100%;

  // @include breakpoint(large) {
  //   // width: $esp-main-width;
  //   border-radius: 1em;
  //   overflow: hidden;
  //   background: #fff;
  //   box-shadow: 0 0.5em 1.5em -.75em rgba(#000,.5);
  // }

  .ui.menu {
    margin: 0;
  }

  form {
    height: calc(100% - 47px);
  }

  .scroll-area {
    max-height: calc(100% - 68px);

    .scroll-content {
      padding: 1em;
    }
  }

  .ui.form {
    & > footer {
      padding-left: 0.5em;
      padding-right: 0.5em;

      .ui.button {
        padding-left: 0.75em;
        padding-right: 0.75em;
      }

      @include breakpoint(medium) {
        padding-left: 1em;
        padding-right: 1em;

        .ui.button {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }
    }

    .field .ui.transparent.input > input[type='text'] {
      padding-bottom: 0 !important;
    }
  }
}

// IE work around
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  .esp-announcement-manage {
    .scroll-area {
      .grid {
        .four {
          max-width: 30%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
