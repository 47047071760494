.block {
  &[data-template='barista01'] {
    text-align: center;

    &:first-of-type {
      padding-top: 1em;
    }

    > * + * {
      margin-top: 1em;
    }

    .section {
      @for $i from 3 through 8 {
        &:nth-of-type(#{$i}) {
          transition-delay: (($i - 2) * 1500ms);
        }
      }

      + .section {
        margin-top: 2em;
      }

      .bubble {
        font-size: 1em;
        line-height: 1.375em;
        display: inline-block;
        padding: 0.75em;
        border-radius: 2px;
        max-width: 85%;
        position: relative;

        @include breakpoint(medium) {
          font-size: 1.25em;
        }
      }
    }

    .barista {
      margin-top: 0.75em;
      margin-bottom: 0.75em;
      opacity: 1 !important;
      transform: scale(1) !important;
      img,
      svg {
        max-width: 18%;

        @include breakpoint(medium) {
          max-width: 28%;
        }
      }

      + .section {
        margin-top: 0;
      }
    }
  }
}
