// Menu Toggle Variables
// ---------------------

$menu-toggle-size: 32px;
$menu-toggle-color: darken(map-get($demo-colors, primary), 20%);

.menu-toggle {
  cursor: pointer;
  appearance: none;
  border: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  top: 1em;
  left: 1em;
  width: $menu-toggle-size;
  height: $menu-toggle-size;
  position: absolute;
  z-index: ($menu-zindex + 10);

  > span {
    display: block;
    width: 100%;
    height: $menu-toggle-size/9;
    background: $menu-toggle-color;
    margin-top: $menu-toggle-size/6;

    transform-origin: center center;
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &:hover,
  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  @include breakpoint(medium) {
    display: none;
  }
}

.menu-scrim {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

body.menu-open {
  .menu-toggle > span {
    background-color: #fff;

    &:nth-child(1) {
      transform: rotate3d(0, 0, 1, 135deg)
        translate3d($menu-toggle-size/5.125, -$menu-toggle-size/5.125, 0);
    }

    &:nth-child(2) {
      transform: translate3d(-300%, 0, 0);
    }

    &:nth-child(3) {
      transform: rotate3d(0, 0, 1, -135deg)
        translate3d($menu-toggle-size/5.125, $menu-toggle-size/5.125, 0);
    }
  }

  .menu-scrim {
    z-index: 50;
    opacity: 0.5;

    @include breakpoint(medium) {
      display: none;
    }
  }

  #menu-open {
    background: $menu-background-active;
  }

  #esp-nav {
    .ui.list.main-nav {
      > .item.active {
        background: $menu-background;
        &:after {
          background: $menu-background;
        }

        &.nav-get-help {
          &:after {
            box-shadow: 0 -1px 0 0 rgba(#000, 0.08) !important;
          }
        }
      }
    }
    .ui.list.more-nav {
      transform: translate3d(0, 0, 0);

      @include breakpoint(medium) {
        transform: scaleY(1);
        opacity: 1;
        max-height: 250%;
      }
    }
  }
}

// .back-button {
//   @include breakpoint(large) {
//     display: none !important;
//   }
// }
