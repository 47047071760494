.block {
  &[data-template='resetPasswordMethod01'] {
    padding: 10%;
  }

  &[data-template='resetPasswordCode01'] {
    padding: 10%;

    .primary.button {
      width: 100%;
    }

    .field {
      width: 100%;
    }
  }
}
