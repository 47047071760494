._ {
  text-align: left;
  label {
    margin-top: 20px !important;
  }
}

.checkbox {
  text-align: left;
}
