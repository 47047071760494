.block {
  &[data-template='bundleSelect01'] {
    padding: 0;
  }

  &[data-template='bundleSelect02'] {
    padding: 0;

    .ui.list > .item > .image-container {
      .ui.image {
        height: auto;
      }
    }
  }
}
