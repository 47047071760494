.esp-profile {
  height: 100%;

  > .ui.menu {
    position: absolute;
    z-index: 9999;
    width: 100%;
    background: rgba(#fff, 0.3);

    > .item {
      color: #000;
      text-shadow: 0 0 1em #fff;
    }

    @include breakpoint(large) {
      display: none;
    }
  }

  .esp-hero {
    padding-top: calc(47px + 0.75em);

    @include breakpoint(large) {
      padding-top: 2em;
    }
  }

  .tab-content {
    height: 100%;
    max-height: calc(100% - 195px);

    @include breakpoint(large) {
      max-height: calc(100% - 160px);
    }

    .scroll-content {
      padding: 1em;

      .details {
        > * {
          transform: translate3d(0, 0, 0);
        }

        > .ui.button {
          margin-bottom: 1em;
        }
      }
    }
  }

  .details {
    .ui.list {
      & > .item {
        i.icon {
          display: inline-block;
          width: 1.75rem;
          float: left;
          font-size: 1.375em;
        }
      }
    }
  }

  @include breakpoint(large) {
    background: #fff;
    border-radius: 1em;
    overflow: hidden;
    max-width: $esp-main-width;
    box-shadow: 0 0.5em 1.5em -0.75em rgba(#000, 0.5);
  }
}
