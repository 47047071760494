.esp-select-user {
  .item {
    &[role='option'] {
      .ui.image {
        width: 2em;
        height: 2em;
      }
    }
  }

  div[role='alert'] {
    .ui.image {
      height: 2em;
      width: 2em;
    }
  }
}
