body.app-admin {
  .esp-page {
    padding-top: map-get($esp-header-height, large);
  }

  .admin-section {
    padding-left: 3.5em;
    @include breakpoint(xlarge) {
      padding-left: 0;
    }
  }
}

#esp-admin-header {
  height: map-get($esp-header-height, large);
  transition: background 500ms ease;

  img.espressive-logo {
    display: none;
  }

  .ui.avatar.image {
    width: 3rem;
    height: 3rem;
  }
}

body.dpc {
  #esp-admin-header {
    background: map-get($demo-colors, primary) !important;

    img.tenant {
      display: none;
    }

    img.espressive-logo {
      display: block;
    }
  }
}

.admin-page .esp-page {
  padding-top: map-get($esp-header-height, large);
}

#esp-admin-nav {
  background: #f1f1f1;
  width: 80%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  bottom: 0;
  top: 0;
  z-index: $menu-zindex;
  box-shadow: 0 0 1.5em -0.25em rgba(#000, 0), 0 1px 0 1px rgba(#000, 0.05);

  transition: all 200ms ease-in-out;
  .item {
    color: map-get($text-colors, default-color);
  }
  @include breakpoint(medium) {
    width: 55px;
    top: map-get($esp-header-height, large);

    &:hover {
      width: map-get($esp-menu-width, large);
      box-shadow: 0 0 1.5em -0.25em rgba(#000, 0.35),
        0 1px 0 1px rgba(#000, 0.05);

      .ui.list > a.item {
        &.active {
          &:before {
            opacity: 1;
          }

          .content {
            color: #fff;
          }
        }

        .content {
          padding-left: 0.75em;
          opacity: 1;
        }
      }
    }
  }

  .ui.list {
    > a.item {
      &.active {
        background-color: #c1c1c1;

        .icon {
          color: #fff;
        }
      }

      &:before {
        opacity: 0;
      }

      .content {
        padding-left: 0;
        white-space: nowrap;
        opacity: 0;
        max-width: 0;
        transition: opacity 400ms ease-in-out;
      }

      .icon {
        width: 1.5em;
        font-size: 1.5em;
        padding: 0.15em 0 0;
      }
    }
  }
}
