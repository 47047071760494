.esp-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;

  width: 100%;
  padding: 0 48px;

  .button.button-block {
    text-transform: uppercase;
    margin: 1em 0;
  }
}

// TEMP STYLE //
.esp-footer-workflow {
  &[data-template='workflow-bottom'] {
    button.ui.button {
      padding: 0 1em;
      height: 2.75em;
      border-radius: 4px;
      font-size: 1rem;
      color: #fff;
      background-color: #ff1c32;
      width: 100%;
    }
  }
}
