body:not(.editor-page) {
  position: fixed;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

#app,
.app-root,
.esp-page {
  height: 100%;
}

body#os-ios {
  .esp-app-header {
    padding-top: $carrier-height-ios;
    height: map-get($esp-header-height, small) + $carrier-height-ios;

    @include breakpoint(medium) {
      height: map-get($esp-header-height, large) + $carrier-height-ios;
      padding-top: $iphone-notch-safe-top;
    }

    @media #{$media-iphone-notch} {
      height: map-get($esp-header-height, small) + $carrier-height-ios;
      padding-top: $iphone-notch-safe-top;
    }

    @media #{$media-iphone-14-pro} {
      padding-top: 43px;
      height: 90px;
    }
  }
}

body#os-android {
  .esp-app-header {
    padding-top: $carrier-height-android;
    height: map-get($esp-header-height, small) + $carrier-height-android;

    @include breakpoint(medium) {
      height: map-get($esp-header-height, large) + $carrier-height-android;
    }
  }
}

.esp-app-header {
  background: darken(map-get($demo-colors, primary), 2%);
  height: map-get($esp-header-height, small);
  position: relative;

  display: flex;
  align-items: center;

  > .brand,
  > .actions {
    position: relative;
  }

  > .brand,
  > .actions {
  }

  > .brand {
    width: 30vw;
    padding: 0.5em 0.75em;

    @include breakpoint(medium) {
      padding: 0.75em;
      width: map-get($esp-menu-width, small);
    }

    @include breakpoint(xlarge) {
      padding: 0.75em;
      width: map-get($esp-menu-width, large);
    }
  }

  > .brand {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;

      @include breakpoint(medium) {
        justify-content: center;
      }
    }

    img {
      width: auto !important;
      height: auto !important;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > .actions {
    width: 70vw;
    text-align: right;
    padding: 0.5em 0.75em;

    @include breakpoint(medium) {
      padding: 0.75em;
      width: map-get($esp-menu-width, small);
    }

    @include breakpoint(xlarge) {
      padding: 0.75em;
      width: map-get($esp-menu-width, large);
    }

    > * {
      display: table-cell;

      &:first-child {
        width: 100%;
      }
    }

    .ui.label.header-cart-item {
      right: 1.5em;
      top: 0;
      background-color: transparent !important;
    }

    .ui.button.basic.icon.transparent {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

      &:hover,
      &:focus {
        background-color: transparent !important;
      }
    }

    // Mobile only
    .header-search-modal {
      @include breakpoint(medium) {
        display: none;
      }
    }

    .header-cart-modal {
      position: relative;

      .icon {
        font-size: 1.5rem;
        margin: -0.5em -0.25em;
      }
      .ui.label {
        background: transparent !important;
        padding: 0.125em;
        top: 5%;
        left: 45%;
        right: auto;
        transform: translateX(-50%);
        font-size: 0.875rem;
      }
    }
  }

  @include breakpoint(medium) {
    // padding-top: 0 !important;
    height: map-get($esp-header-height, large);
    z-index: $menu-zindex + 2;
  }
}

.esp-content {
  height: calc(100% - #{$esp-touchbar-height});

  @include breakpoint(medium) {
    height: 100%;
    margin-left: map-get($esp-menu-width, small);
  }

  @include breakpoint(xlarge) {
    margin-left: map-get($esp-menu-width, large);
  }
}

// body.esp-catalog {
//
//   .esp-app-header {
//     padding-right: calc(54px - 1em);
//
//     @include breakpoint(medium) {
//       padding-right: 0;
//     }
//   }
// }

.esp-section-title {
  background: #f0f0f0;
  height: 0;
  display: none;
  position: relative;

  @include breakpoint(medium) {
    display: block;
    height: $esp-section-title-height;
    box-shadow: inset 1px 0 0 1px darken(#f0f0f0, 8%);
  }

  .ui.menu {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 110;
    height: map-get($esp-header-height, small);

    .icon {
      color: #fff;
    }

    > .item {
      display: none;
    }

    @include breakpoint(medium) {
      position: relative;
      bottom: auto;
      right: auto;
      z-index: auto;
      margin-bottom: auto;
      height: 100%;

      .icon {
        color: map-get($text-colors, default-color);
      }

      > .item {
        display: flex;
      }
    }
  }

  h4 {
    margin: 0;
    padding: 0.4em 0.625em;
    font-weight: 400;
  }
}

.esp-panels {
  height: calc(100% - #{$esp-touchbar-height});

  @include breakpoint(medium) {
    height: calc(
      100% - #{$esp-section-title-height + map-get($esp-header-height, large)}
    );
    position: relative;
  }
}

body#os-ios {
  .esp-panels {
    @media #{$media-iphone-notch} {
      height: calc(100% - #{$esp-touchbar-height + $iphone-notch-safe-bottom});
    }

    @include breakpoint(medium) {
      height: calc(
        100% - #{$esp-section-title-height + map-get($esp-header-height, large)} -
          #{$carrier-height-ios}
      );
    }
  }
}

body#os-android {
  .esp-panels {
    @include breakpoint(medium) {
      height: calc(
        100% - #{$esp-section-title-height + map-get($esp-header-height, large)} -
          #{$carrier-height-android}
      );
    }
  }
}

body#os-ios {
  @media #{$media-iphone-notch} {
    .x-overlay {
      display: block !important;
    }

    #esp-nav {
      padding-bottom: $iphone-notch-safe-bottom;
      height: $iphone-notch-safe-bottom + $esp-touchbar-height;

      &:after {
        content: ' ';
        position: absolute;
        display: block;
        height: $iphone-notch-safe-bottom + 1px;
        background-color: #fff;
        width: 100%;
      }

      .ui.list.more-nav {
        top: 0 !important;
        padding-top: $iphone-notch-safe-top !important;
      }
    }
  }

  @media #{$media-ipad-tier3} {
    #esp-nav {
      height: auto !important;
      top: map-get($esp-header-height, large) !important;
    }
  }

  @media #{$media-ipad-tier3-landscape} {
    #esp-nav {
      top: map-get($esp-header-height, large) + $carrier-height-ios !important;
    }
  }

  @media #{$media-ipad-tier6} {
    #esp-nav {
      height: auto !important;
      top: map-get($esp-header-height, large) !important;
    }
  }

  @media #{$media-iphone-6} {
    #esp-nav {
      padding-bottom: 0;
      height: $esp-touchbar-height;
    }
  }
}

.esp-secondary,
.esp-main {
  position: absolute;
  z-index: 10;
}

.esp-secondary {
  //  background: lighten(map-get($demo-colors,primary),30%);
  width: 100%;
  bottom: $esp-touchbar-height;
  top: map-get($esp-header-height, small);

  div[data-component='ListCardHeader'] {
    padding-right: 10px;
    h4 {
      word-break: break-word;
    }
  }

  @include breakpoint(medium) {
    top: 0;
    bottom: 0;
  }

  @include breakpoint(large) {
    display: block;
    width: map-get($esp-secondary-width, small);
    box-shadow: 0 1px 0 1px #f0f0f0;
  }

  @include breakpoint(xlarge) {
    width: map-get($esp-secondary-width, large);
  }
}

body#os-ios {
  .esp-secondary {
    top: map-get($esp-header-height, small) + $carrier-height-ios;

    @media #{$media-iphone-notch} {
      bottom: $esp-touchbar-height + $iphone-notch-safe-bottom;
    }

    @include breakpoint(medium) {
      top: 0;
    }
  }
}

body#os-android {
  .esp-secondary {
    top: map-get($esp-header-height, small) + $carrier-height-android;

    @include breakpoint(medium) {
      top: 0;
    }
  }
}

.esp-main {
  background: #f9f9f9;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ($menu-zindex + 10);

  transition: transform 200ms ease-in-out;
  transform: translateX(100%);

  @include breakpoint(medium) {
    top: 0;
    z-index: 10;
    bottom: 0;
  }

  @include breakpoint(large) {
    display: block;
    width: calc(100% - #{(map-get($esp-secondary-width, small) + 1px)});

    transition: none;
    transform: none;
  }

  @include breakpoint(xlarge) {
    width: calc(100% - #{(map-get($esp-secondary-width, large) + 1px)});
  }
}

body#os-ios {
  .esp-main {
    top: $carrier-height-ios;

    @media #{$media-iphone-notch} {
      top: $iphone-notch-safe-top;
    }

    @include breakpoint(medium) {
      top: 0;
    }
  }
}

body#os-android {
  .esp-main {
    top: $carrier-height-android;

    @include breakpoint(medium) {
      top: 0;
    }
  }
}

body {
  &.main-active {
    .esp-main {
      transform: translate3d(0, 0, 0);
      // height: 100%;
      backface-visibility: hidden;

      @include breakpoint(medium) {
        height: auto;
      }
    }

    &.workflow-modal {
      .esp-main {
        height: 100%;

        @include breakpoint(medium) {
          height: auto;
        }
      }
    }
  }

  &.main-padding {
    .esp-main {
      @include breakpoint(large) {
        padding: 1em;
        height: auto;
      }
    }
  }
}
