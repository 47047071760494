.Preview,
.SafeArea {
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}

.Preview {
  background-position: center;
  background-size: cover;

  :global img {
    max-width: 100%;
  }

  .PreviewImage {
    position: relative;
    z-index: 5;
    width: 100%;
    display: block;
  }

  .Logo {
    z-index: 10;
    position: absolute;
  }

  // These are avatar images
  .Barista,
  .UserAvatar {
    display: none;
  }

  // This is the brand color which we need to use to mask any
  // potential background image for showing in the preview.
  .LoginBackground {
    display: none;
  }

  // This is used to set an alt color for display on the header
  // in an application preview.
  .HeaderBackground {
    display: none;
  }

  &[data-type='login'] {
    .Logo {
      max-width: 23%;
      left: 50%;
      bottom: 60%;
      transform: translateX(-50%);
    }

    .LoginBackground {
      z-index: 1;
      display: block;
      position: absolute;
      top: 45%;
      bottom: 25%;
      left: 33%;
      right: 33%;
    }
  }

  &[data-type='app'] {
    // Just in case someone does not read the types and code and
    // still sets a background on these other preview types.
    background-image: none !important;

    .Logo {
      margin: 0.25em;
      max-height: 10%;
    }

    .HeaderBackground {
      z-index: 1;
      display: block;
      position: absolute;
      top: 0;
      bottom: 80%;
      width: 100%;
    }
  }

  &[data-type='chat'] {
    // Just in case someone does not read the types and code and
    // still sets a background on these other preview types.
    background-image: none !important;

    .Logo {
      display: none;
    }

    .Barista,
    .UserAvatar {
      z-index: 5;
      position: absolute;
      display: block;
      width: 3rem;
      height: 3rem;
    }

    .Barista {
      border-radius: 0.25em;
      top: 1rem;
      left: 1rem;
    }

    .UserAvatar {
      border-radius: 50%;
      right: 1rem;
      top: 50%;
    }
    // styles for default user avatar when there is no image for the user
    .DefaultAvatar {
      z-index: 5;
      position: absolute;
      display: block;
      width: 2.5rem;
      right: 1rem;
      top: 50%;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}

.SafeArea {
  width: 8rem;
  height: 8rem;

  .Overlay {
    opacity: 0.5;
    position: absolute;
    display: block;
    width: 100%;
  }
}
