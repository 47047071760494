._ {
  pre {
    width: 400px;
    overflow-x: auto;
  }

  * > input[type='text'] {
    background-color: none;
    border: none;
  }
}
