body.onboard-workflow {
  .esp-workflow {
    .top.menu {
      // hides workflow's Exit button on /a and /ob routes
      .item.menu-leave {
        display: none;
      }
    }
  }
}
