.esp-catalog-detail {
  .ui.segment.link .content {
    word-wrap: break-word;
  }

  .esp-slider {
    height: 20em;
  }

  .description,
  .configuration {
    .ui.segment {
      .content {
        max-height: 3em;
        overflow: hidden;
      }
    }
  }

  .add-product {
    text-align: center;
  }

  .field.no-label {
    .button {
      margin-top: 1.6em;
    }
  }

  .field.centered > label {
    text-align: center;
    margin: 0;
  }

  .field.centered > div.input > input {
    text-align: center;
  }
}

.esp-image-gallery {
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }

  .image {
    max-width: 100%;
    text-align: center;

    &.main {
      margin: 1em auto;
      width: 100%;
      height: 24em;
      max-height: 100%;
      max-width: 24em;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .thumbs {
    margin-top: 1rem;

    > .image {
      width: calc(25% - 0.75rem);
      float: left;
      max-height: 10em;
      max-width: 10em;

      + .image {
        margin-left: 1rem;
      }

      img {
        cursor: pointer;
        opacity: 0.8;
        transition: all 200ms ease-in-out;

        &:hover,
        &.active {
          opacity: 1;
        }

        &.active {
          box-shadow: 0 0 0 0.25rem map-get($demo-colors, primary);
        }
      }
    }
  }
}
