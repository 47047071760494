#esp-cards {
  //  background: map-get($demo-colors,primary);
  //  color: #fff;
  height: 100%;

  .esp-card {
    position: relative;
    overflow: hidden;
    transition: opacity 300ms ease-in-out;

    &.loading {
      opacity: 0.8;
      animation-name: fadepulse;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;

      .front {
        .ui.header,
        .card-body .card-content ul li {
          display: block;
          color: rgba(0, 0, 0, 0);
          background: #dcddde;
          border-radius: 10px;
        }
      }
    }

    &.active {
      a.channel-link {
        cursor: default;
        pointer-events: none;
      }
    }

    .back {
      position: absolute;
      z-index: 1;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .ui.buttons {
        display: list-item;
        height: 100%;
        text-align: right;

        .ui.button {
          display: table-cell;
          vertical-align: top;
          height: 100%;
          width: 25%;
          font-size: 0.8rem;
          color: #fff;
          background: transparent;
          border-left: 1px solid rgba(#000, 0.1);

          &:hover {
            background: rgba(#000, 0.08);
          }

          &:active {
            background: rgba(#000, 0.16);
          }

          .icon {
            display: block;
            margin: 0 auto 0.75em;
          }

          &:first-child {
            border-left: none;
          }
        }

        /* IE11 Fixes */
        @media all and (-ms-high-contrast: none) {
          *::-ms-backdrop,
          .ui.button {
            display: inline-block;
          }
        }
      }

      //      @include breakpoint(medium) {
      //        position: relative;
      //        z-index: 10;
      //      }
    }

    .front {
      background: #fff;
      padding: 1em;
      position: relative;
      z-index: 10;
      transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &:before {
        content: ' ';
        background: #fff;
        position: absolute;
        height: 100%;
        width: 10%;
        left: -10%;
        top: 0;
      }

      .actions-toggle {
        float: right;
        border: none;
        font-size: 1.25em;
        padding: 0.25em;
        margin: 0 0 0 4px;
        background: none !important;
      }

      //      @include breakpoint(medium) {
      //        z-index: 1;
      //        //transition: none;
      //      }
    }

    &.announcement {
      margin: 0.25rem;
      border: 1px solid rgba(#000, 0.2);

      .front {
        padding: 0.75em;
        background: #fafafa;
        transition: none;
      }

      + .announcement {
        margin-top: 0;
      }
    }

    .card-headers {
      perspective: 1000px;

      .ui.header {
        animation-name: rotateIn;
        animation-iteration-count: infinite;
        animation-duration: 8000ms;

        &:nth-of-type(2) {
          opacity: 0;
          animation-delay: 4000ms;
          position: absolute;
          top: 0;
        }
      }
    }

    .ui.header {
      margin: 0 0 0.5em;
      //color: #fff;

      @include breakpoint(medium) {
        padding-right: 40px;
      }

      .icon:only-child {
        margin-right: 0.5rem;
        height: 0.75em;
        line-height: 0.75;
      }
    }

    .card-body {
      width: 100%;

      .card-image,
      .card-content {
        display: inline-block;
        vertical-align: top;
      }

      .card-image {
        position: relative;
        width: 56px;

        .isvg {
          width: 100%;
        }

        /* IE11 Fixes */
        @media all and (-ms-high-contrast: none) {
          *::-ms-backdrop,
          .ui.image,
          .isvg,
          img {
            height: 56px;
          }
        }
      }

      .card-content {
        font-size: 0.875rem;
        line-height: 1.25;
        width: calc(100% - 56px);
      }

      ul {
        margin: 0;
        padding-left: 0;

        li {
          padding-left: 1em;
        }

        &.multiple {
          &.theirs {
            li.current:before {
              content: '\f060';
            }
          }

          &.mine {
            li.current:before {
              content: '\f061';
            }
          }

          li {
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            width: 100%;
            overflow: hidden;
            padding-left: 2.25em;

            &.complete {
              opacity: 0.7;
              text-decoration: line-through;

              &:before {
                content: '\f00c';
              }
            }
          }
        }

        li {
          list-style: none;

          &:before {
            font-family: Icons;
            display: inline-block;
            margin-left: -1.5em;
            width: 1.5em;
          }

          & + li {
            margin-top: 0.375em;
          }
        }
      }
    }

    .card-footer {
      display: table;
      width: 100%;
      margin-top: 0.5em;

      .card-meta,
      .card-action {
        display: table-cell;
        vertical-align: bottom;
      }

      .card-meta {
        font-size: 0.8rem;

        .icon {
          font-size: 1.25rem;
        }
      }

      .card-action-button {
        min-width: 120px;
      }
    }
  }
}

@keyframes rotateIn {
  from,
  to {
    transform-origin: center;
    transform: rotateX(100deg);
    opacity: 0;
  }

  5%,
  45% {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }

  50% {
    transform-origin: center;
    transform: rotateX(-100deg);
    opacity: 0;
  }
}
