.block {
  &[data-template='image01'] {
    .image {
      max-width: 100%;

      &.web {
        display: none;
        @include breakpoint(medium) {
          display: block;
        }
      }

      &.mobile {
        @include breakpoint(medium) {
          display: none;
        }
      }
    }
  }
}
