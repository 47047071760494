div.esp-about-me {
  background: rgb(249, 250, 251);
  border-radius: 3px;
  margin-top: 1em;
  padding: 0.75em 1em;
  .content {
    font-size: 0.9em;
    &:before,
    &:after {
      font-family: Icons;
    }
    &:before {
      content: '\f10d';
      padding-right: 0.5em;
    }
    &:after {
      content: '\f10e';
      padding-left: 0.5em;
    }
  }

  .ui.image {
    float: right;
    width: 3.75em;
    height: 3.75em;
    margin-left: 1em;
  }
}
