// <div class="notification-bar">
//   <div class="notification-bar-wrapper" onClick={this.props.onClick}>
//     <span class="notification-bar-message">{this.props.message}</span>
//     <span class="notification-bar-action">{this.props.action}</span>
//   </div>
// </div>

.notification-list {
  position: absolute;
  bottom: 3.5em;
  padding: 0 0.5em;
  right: 0;
  z-index: 1001;
  max-width: 100%;

  @include breakpoint(medium) {
    max-width: calc(100% - #{(map-get($esp-menu-width, small))});
    bottom: 0;
    padding: 0 1em;
  }

  @include breakpoint(xlarge) {
    max-width: calc(100% - #{(map-get($esp-menu-width, large))});
  }
}

.notification-bar {
  background: #000;
  padding: 0.75em 1em;
  width: auto;
  color: #fff;
  border-radius: 4px;
  transition: 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: translateX(110%);
  margin-bottom: 0.5em;
  box-shadow: 0 0.5em 1.5em -0.75em rgba(0, 0, 0, 0.5);
  font-size: 0.875em;

  @include breakpoint(medium) {
    padding: 1em;
    margin-bottom: 1em;
    font-size: 1em;
  }

  &.success {
    background: #0a0;
  }

  &.info {
    background: #2b60de;
  }

  &.warn {
    background: #fbb917;
  }

  &.error {
    background: #a00;
  }

  &.notification-bar-active {
    transform: translateX(0);
  }

  .notification-bar-wrapper {
    .notification-bar-title {
      font-weight: 700;
      padding-right: 0.75em;
    }

    .notification-bar-action {
    }
  }
}
