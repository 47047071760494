@import './variables.scss';

.DayPickerKeyboardShortcuts__show,
.DayPickerKeyboardShortcuts__close {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;

  &:active {
    outline: none;
  }
}

.DayPickerKeyboardShortcuts__show {
  width: 22px;
  position: absolute;
  z-index: $react-dates-z-index + 2;
}

.DayPickerKeyboardShortcuts__show--bottom-right {
  border-top: 26px solid transparent;
  border-right: 33px solid $react-dates-color-primary;
  bottom: 0;
  right: 0;

  &:hover {
    border-right: 33px solid #008489;
  }

  .DayPickerKeyboardShortcuts__show_span {
    bottom: 0;
    right: -28px;
  }
}

.DayPickerKeyboardShortcuts__show--top-right {
  border-bottom: 26px solid transparent;
  border-right: 33px solid $react-dates-color-primary;
  top: 0;
  right: 0;

  &:hover {
    border-right: 33px solid #008489;
  }

  .DayPickerKeyboardShortcuts__show_span {
    top: 1px;
    right: -28px;
  }
}

.DayPickerKeyboardShortcuts__show--top-left {
  border-bottom: 26px solid transparent;
  border-left: 33px solid $react-dates-color-primary;
  top: 0;
  left: 0;

  &:hover {
    border-left: 33px solid #008489;
  }

  .DayPickerKeyboardShortcuts__show_span {
    top: 1px;
    left: -28px;
  }
}

.DayPickerKeyboardShortcuts__show_span {
  color: $react-dates-color-white;
  position: absolute;
}

.DayPickerKeyboardShortcuts__panel {
  overflow: auto;
  background: $react-dates-color-white;
  border: 1px solid $react-dates-color-border;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: $react-dates-z-index + 2;
  padding: 22px;
  margin: 33px;
}

.DayPickerKeyboardShortcuts__title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.DayPickerKeyboardShortcuts__list {
  list-style: none;
  padding: 0;
}

.DayPickerKeyboardShortcuts__close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: $react-dates-z-index + 2;

  svg {
    height: 15px;
    width: 15px;
    fill: $react-dates-color-gray-lighter;

    &:hover,
    &:focus {
      fill: $react-dates-color-gray-light;
    }
  }

  &:active {
    outline: none;
  }
}

.KeyboardShortcutRow {
  margin: 6px 0;
}

.KeyboardShortcutRow__key-container {
  display: inline-block;
  white-space: nowrap;
  text-align: right;
  margin-right: 6px;
}

.KeyboardShortcutRow__key {
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase;
  background: $react-dates-color-gray-lightest;
  padding: 2px 6px;
}

.KeyboardShortcutRow__action {
  display: inline;
  word-break: break-word;
  margin-left: 8px;
}

.DayPickerKeyboardShortcuts__panel--block {
  .KeyboardShortcutRow {
    margin-bottom: 16px;
  }

  .KeyboardShortcutRow__key-container {
    width: auto;
    text-align: left;
    display: inline;
  }

  .KeyboardShortcutRow__action {
    display: inline;
  }
}
