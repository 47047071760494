.esp-casemgmt-detail {
  .esp-main-panel.esp-chat {
    height: calc(100% - 46px - 57px);

    > .scroll-area {
      height: calc(100% - 44px) !important;
    }
  }

  .ui.form > footer {
    background: #fafafa;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1em;
    textalign: center;
  }

  .ui.avatar.image {
    width: 3em;
    height: 3em;

    img,
    svg {
      width: 3em;
      height: 3em;
    }
  }

  .field.case-inline {
    label {
      display: inline;
    }
    .ui.input {
      display: inline;
    }
  }
}

form.casemgmt-info {
  height: calc(100% - 100px);
  .esp-casemgmt-detail & {
    .ui.menu {
      margin: 0;
    }

    form {
      height: calc(100% - 47px);
    }

    .scroll-area {
      max-height: calc(100% - 70px);

      .scroll-content {
        padding: 1em;
      }
    }

    .ui.form > footer {
      padding-left: 0.5em;
      padding-right: 0.5em;

      .ui.button {
        padding-left: 0.75em;
        padding-right: 0.75em;
      }

      @include breakpoint(medium) {
        padding-left: 1em;
        padding-right: 1em;

        .ui.button {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }
    }
  }
}

body#os-ios,
body#os-android {
  .esp-casemgmt-detail {
    .esp-main-panel.esp-chat {
      height: calc(100% - 46px - 57px);
      .scroll-area {
        // height: calc(100% - 91px - env(safe-area-inset-bottom)) !important;
      }
    }
  }
}
