.esp-blocks .block {
  &[data-template='cart01'] {
    padding-left: 0;
    padding-right: 0;
    position: relative;

    .esp-cart {
      margin-bottom: 108px;

      .ui.list {
        .ui.image {
          max-height: 4em;
          height: auto;
        }
      }
    }

    .actions {
      padding: 1em 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fafafb;
      border-top: 1px solid rgba(34, 36, 38, 0.15);

      .ui.button {
        margin: 0 auto;
        display: block;

        + .ui.button {
          margin-top: 1em;
        }
      }
    }
  }
}
